import { mapGetters, mapMutations } from 'vuex';
import * as T from '@/store/types';
import { copy } from '@/utils/common';
import QrCodeSrc from '@/assets/images/qrcode.png';
import View_52bdap1dxmg_PosterSrc from '@/assets/images/point-52bdap1dxmg-poster.png';
import View_72lhuc40od_PosterSrc from '@/assets/images/point-72lhuc40od-poster.jpg';
const viewsData = [{
  id: '52bdap1dxmg',
  title: '先锋基金对投资顾问价值的量化研究',
  name: '图译视角',
  date: '2022-09-09',
  poster: View_52bdap1dxmg_PosterSrc
}, {
  id: '72lhuc40od',
  title: '罗素投资对投资顾问价值的研究',
  titleStyle: {
    color: 'inherit'
  },
  name: '图译视角',
  date: '2022-09-28',
  poster: View_72lhuc40od_PosterSrc
}, {} // 凑够三个，样式不变形
];
export default {
  name: 'HomeContent',

  data() {
    return {
      QrCodeSrc,
      email: 'joey.zhang@fundit.com.cn',
      viewsData: viewsData.slice(0, 3)
    };
  },

  computed: { ...mapGetters({
      rootScrollInfo: T.GET_ROOT_SCROLL_INFO
    }),

    scrollDirection() {
      return this.rootScrollInfo.direction || 'down';
    }

  },
  methods: { ...mapMutations({
      setNavIndex: T.SET_CURRENT_NAV_INDEX
    }),

    getAnimateStatus(status) {
      // 当模块是'entry'(进入状态)并且是向上滚动
      // 或者是模块式'leave'(离开状态)并且是向下滚动的时候
      // 这种状态适合触发 离开动画
      // 反之适合触发  进入动画
      if (status === 'entry' && this.scrollDirection === 'up' || status === 'leave' && this.scrollDirection === 'down') {
        return 'out';
      } else if (status === 'entry' && this.scrollDirection === 'down' || status === 'leave' && this.scrollDirection === 'up') {
        return 'in';
      }
    },

    copyEmail() {
      copy(this.email);
    },

    toDetails(id) {
      if (id) {
        this.$router.push(`/points/point/${id}`);
      }
    }

  }
};