import { ref, computed, onMounted, watchEffect } from 'vue';
import { useStore } from 'vuex';
import * as T from '@/store/types';
export default {
  name: 'AnimateContainer',
  props: {
    uniqueKey: {
      type: [String, Number],
      required: true
    }
  },

  setup(props, {
    emit
  }) {
    const store = useStore();
    const ele = ref(null);
    const status = computed(() => {
      const {
        scrollTop
      } = store.getters[T.GET_ROOT_SCROLL_INFO];

      if (ele.value) {
        const {
          offsetTop,
          offsetHeight
        } = ele.value;
        const winHeight = window.innerHeight;

        if (scrollTop + winHeight <= offsetTop) {
          // bottom
          return 'hidden';
        } else if (scrollTop + winHeight > offsetTop && scrollTop + winHeight <= offsetTop + offsetHeight) {
          return 'entry';
        } else if (scrollTop + winHeight > offsetTop + offsetHeight && scrollTop < offsetTop) {
          return 'in';
        } else if (scrollTop >= offsetTop && scrollTop <= offsetTop + offsetHeight) {
          return 'leave';
        } else if (scrollTop > offsetTop + offsetHeight) {
          // top
          return 'hidden';
        }
      }

      return 'null';
    });
    onMounted(() => {
      if (ele.value) {
        const {
          offsetTop,
          offsetHeight
        } = ele.value;
        store.commit(T.SET_ROOT_SCROLL_ELEMENT, {
          key: props.uniqueKey,
          offsetTop,
          offsetHeight,
          ele
        });
      }
    });
    watchEffect(() => {
      emit('status-change', status);
    });
    return {
      ele,
      status
    };
  }

};