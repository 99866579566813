import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import router from './router'
import store from './store'
import App from './App.vue'
import components from './components/index'

// import './registerServiceWorker'
// 全局通用样式
import 'normalize.css'
import 'element-plus/dist/index.css'
import './assets/component/index.less'
import './index.less'
// 引入阿里矢量库图标
import '@/components/SvgIcon/iconfont.js'

const app = createApp(App)
// 引入 Element Plus 插件
app.use(ElementPlus)
// 增加全局属性
// app.config.globalProperties.$axios = axios
// app.config.globalProperties.$accounting = accounting
// 引入 vuex
app.use(store)
// 引入 vue-router
app.use(router)
// 添加全局组件
app.use(components)
// 挂载
app.mount('#app')
