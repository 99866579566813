import { readonly, computed, ref, nextTick } from 'vue';
import { useStore, mapGetters, mapMutations } from 'vuex';
import * as T from '@/store/types';
import { smoothScroll } from '@/utils/common';
import HomeContent from './HomeContent.vue';
import RecordSrc from '@/assets/images/record.png';
import LogoSrc from '@/assets/images/logo.png';
export default {
  components: {
    HomeContent
  },

  setup() {
    const store = useStore();
    const smoothScrolling = ref(false);

    const setNavIndex = index => store.commit(T.SET_CURRENT_NAV_INDEX, index);

    const setAnchor = key => {
      smoothScrolling.value = true;
      const {
        scrollElements
      } = store.getters[T.GET_ROOT_SCROLL_INFO];
      const navHeight = store.getters[T.GET_NAV_HEIGHT];
      const target = scrollElements.find(item => item.key === key);
      return new Promise((resolve, reject) => {
        if (target) {
          scrollTo(Math.max(target.offsetTop - navHeight, 0)).then(() => {
            resolve();
            nextTick(() => {
              smoothScrolling.value = false;
            });
          });
        } else {
          reject();
        }
      });
    };

    const menuList = readonly([{
      value: 'home',
      label: 'TAMP',
      title: 'TAMP财富管理工作台',
      onClick: () => {
        setAnchor('tamp').then(() => setNavIndex(0));
      }
    }, {
      value: 'introduce',
      label: 'DIF',
      title: 'DIF基金数据智能工厂',
      onClick: () => {
        setAnchor('dif').then(() => setNavIndex(1));
      }
    }, {
      value: 'views',
      label: '前沿观点',
      onClick: () => {
        setAnchor('views').then(() => setNavIndex(2));
      }
    }, {
      value: 'contact',
      label: '联系我们',
      onClick: () => {
        setAnchor('contact').then(() => setNavIndex(3));
      }
    }]);
    const currentNavIndex = computed(() => store.getters[T.GET_CURRENT_NAV_INDEX]);
    const trackStyle = computed(() => {
      const width = 100 / menuList.length;
      return {
        width: width - 10 + '%',
        left: width * currentNavIndex.value + '%'
      };
    });

    const scrollTo = (x = 0) => {
      return smoothScroll(document.querySelector('html'), x); // window.scrollTo({
      //   top: x,
      //   behavior: 'smooth',
      // })
    };

    return {
      currentNavIndex,
      setNavIndex,
      smoothScrolling,
      trackStyle,
      menuList,
      recordSrc: RecordSrc,
      LogoSrc,
      scrollTo
    };
  },

  computed: { ...mapGetters({
      navHeight: T.GET_NAV_HEIGHT,
      rootScrollInfo: T.GET_ROOT_SCROLL_INFO
    }),

    scrollTop() {
      return this.rootScrollInfo.scrollTop;
    },

    navStyle() {
      return {
        height: this.navHeight + 'px'
      };
    },

    bodyStyle() {
      return {
        paddingTop: this.navHeight + 'px'
      };
    }

  },

  mounted() {
    window.addEventListener('scroll', this.onRootScroll);
  },

  unmounted() {
    this.resetRootScrollInfo();
    window.removeEventListener('scroll', this.onRootScroll);
  },

  methods: { ...mapMutations({
      setRootScrollInfo: T.SET_ROOT_SCORLL_INFO,
      resetRootScrollInfo: T.SET_CLEAR_SCROLL_INFO
    }),

    onRootScroll(event) {
      if (!this.scrolling && !this.smoothScrolling && event.target === document) {
        this.scrolling = true;
        const {
          scrollTop
        } = document.querySelector('html');
        this.setRootScrollInfo({
          scrollTop,
          direction: this.scrollTop > scrollTop ? 'up' : 'down'
        });
        const {
          scrollElements
        } = this.rootScrollInfo;
        const elesMapping = {};
        scrollElements.forEach(item => {
          const {
            key,
            ele
          } = item;

          if (ele) {
            const {
              offsetTop
            } = ele;
            elesMapping[key] = offsetTop;
          }
        });
        const targetScrollTop = scrollTop + this.navHeight;

        if (targetScrollTop >= elesMapping['contact']) {
          this.setNavIndex(3);
        } else if (targetScrollTop >= elesMapping['views']) {
          this.setNavIndex(2);
        } else if (targetScrollTop >= elesMapping['dif']) {
          this.setNavIndex(1);
        } else {
          this.setNavIndex(0);
        }

        setTimeout(() => {
          this.scrolling = false;
        }, 200);
      }
    }

  }
};