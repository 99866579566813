const context = require.context('./', true, /\.vue$/)

export default function (app) {
  context.keys().forEach((path) => {
    const module = context(path)
    const component = module.default || module
    if (component && component.name) {
      app.component(component.name, component)
    }
  })
}
