import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/home/HomeView.vue'
import { RouterView } from 'vue-router'

const routes = [
  {
    path: '/',
    name: '/',
    // redirect: '/home',
    component: HomeView,
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/points',
    name: 'points',
    redirect: '/points/index',
    component: RouterView,
    children: [
      {
        path: 'index',
        name: 'points-index',
        component: () => import(/* webpackChunkName: "points" */ '../views/points/PointsView.vue'),
      },
      {
        path: 'point/:id',
        name: 'point-details',
        component: () =>
          import(/* webpackChunkName: "point-details" */ '../views/points/PointDetails.vue'),
      },
    ],
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // 始终滚动到顶部
    return { top: 0 }
  },
})

export default router
