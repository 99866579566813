import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-4ea7b6c5"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "iconfont",
  "aria-hidden": "true"
};
const _hoisted_2 = ["xlink:href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["iconfont-container", $setup.containerClass]),
    onClick: _cache[0] || (_cache[0] = (...args) => $setup.onContainerClick && $setup.onContainerClick(...args))
  }, [(_openBlock(), _createElementBlock("svg", _hoisted_1, [_createElementVNode("use", {
    "xlink:href": $setup.xlinkHref
  }, null, 8, _hoisted_2)])), _renderSlot(_ctx.$slots, "default", {}, undefined, true)], 2);
}