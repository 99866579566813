import { createStore } from 'vuex'
import * as T from './types'

export default createStore({
  state: {
    navHeight: 48,
    rootScroll: {
      scrollTop: 0,
      direction: 'down',
      scrollElements: [],
    },
    currentNavIndex: 0,
  },
  getters: {
    [T.GET_NAV_HEIGHT](state) {
      return state.navHeight
    },
    [T.GET_ROOT_SCROLL_INFO](state) {
      return state.rootScroll || {}
    },
    [T.GET_CURRENT_NAV_INDEX](state) {
      return state.currentNavIndex || 0
    },
  },
  mutations: {
    [T.SET_CLEAR_SCROLL_INFO](state) {
      state.rootScroll = { scrollTop: 0, scrollElements: [] }
    },
    [T.SET_ROOT_SCORLL_INFO](state, payload) {
      state.rootScroll = { ...state.rootScroll, ...payload }
    },
    [T.SET_ROOT_SCROLL_ELEMENT](state, payload) {
      const index = state.rootScroll.scrollElements.findIndex((item) => item.key === payload.key)
      if (index === -1) {
        state.rootScroll.scrollElements.push(payload)
      } else {
        state.rootScroll.scrollElements.splice(index, 1, payload)
      }
    },
    [T.SET_CURRENT_NAV_INDEX](state, payload) {
      state.currentNavIndex = payload
    },
  },
  actions: {},
})
