import { ElMessage } from 'element-plus'
/**
 * 生成一个唯一键
 * @returns string
 */
export const getUniqueKey = () => {
  return Number(+Math.random().toString().substring(3) + Date.now()).toString(36)
}

/**
 * 将数组转成mapping
 * @param {array} array
 * @param {string} label
 * @param {string} value
 */
export function toMapping(array, key, label) {
  const mapping = {}
  array.forEach((item, index) => {
    const isObject = Object.prototype.toString.call(item) === '[object Object]'
    const value = isObject && label ? item[label] : item
    if (key) {
      if (isObject && item[key] !== undefined) {
        mapping[item[key]] = value
      }
    } else {
      mapping[index] = value
    }
  })
  return mapping
}

/**
 * 将目标内容复制到剪切板
 * @param {string} message 要复制到剪切板的内容
 * @param {bool} notToast 需要默认的轻提示吗
 */
export function copy(message, notToast = false) {
  let container = document.querySelector('#copy-container')
  if (!container) {
    container = document.createElement('textarea')
    container.id = 'copy-container'
    container.setAttribute('readonly', 'readonly')
    document.body.appendChild(container)
  }
  container.value = message
  container.select()
  if (document.execCommand('copy') && !notToast) {
    ElMessage({ type: 'info', message: '已复制到剪切板', offset: 100, duration: 1000 })
  }
  container.value = ''
}

/**
 * ScrollTo 方法实现，主要是为了兼容safari
 * @param {element} ele 要滚动的dom元素
 * @param {number} to 目标scrollTop
 * @param {number} duration 滚动时长，单位毫秒
 */
export function smoothScroll(ele, to, duration = 300) {
  if (ele.scrollTop === undefined) {
    throw new Error('Must be invoked by an DOM element')
  }
  return new Promise((resolve) => {
    const start = Date.now()
    const end = start + duration
    const initScrollTop = ele.scrollTop
    const myScroll = () => {
      const now = Date.now()
      if (now >= end) {
        ele.scrollTop = to
        window.requestAnimationFrame(() => resolve())
      } else {
        window.requestAnimationFrame(() => {
          // linear
          ele.scrollTop = ((to - initScrollTop) * (now - start)) / duration + initScrollTop
          myScroll()
        })
      }
    }
    myScroll()
  })
}
