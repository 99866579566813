import { ref, computed } from 'vue';
export default {
  name: 'SvgIcon',
  props: {
    icon: {
      type: String,
      required: true,
      default: 'iconset0143'
    }
  },

  setup(props, {
    emit
  }) {
    const acitve = ref(false);
    const xlinkHref = computed(() => {
      return `#es-${props.icon}`;
    });
    const containerClass = computed(() => {
      return acitve.value ? 'acitve' : '';
    });

    const onContainerClick = event => {
      acitve.value = true;
      emit('click', event);
      const timer = setTimeout(() => {
        acitve.value = false;
        clearTimeout(timer);
      }, 1000);
    };

    return {
      acitve,
      xlinkHref,
      containerClass,
      onContainerClick
    };
  }

};