import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-2fb4717a"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "animate-container",
  ref: "ele"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {
    scrollStatus: $setup.status
  }, undefined, true)], 512);
}